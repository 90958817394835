import { graphql, navigate } from "gatsby"
import React, { Component } from "react"
import "@babel/polyfill";

import Layout from "../layouts"
import Head from "../components/head"
import SingleCountryHero from "../components/single-country-hero"
import CountryBgImage from '../images/country-hero-background-min.png'

import '../css/single-country.css';

import YoastSeoParts from '../fragments/seo';

class SingleCountryTemplate extends Component {

  render() {
    const { country } = this.props.data;
    const cpt = country.cptCountries;
    const { seo } = country;

    if(cpt.redirect && typeof window !== 'undefined')
      navigate(cpt.redirectTo.wpURI, { replace: true })

    return (
      <Layout>
        <Head title={seo.title} description={seo.metaDesc} meta={seo} />

        <SingleCountryHero image={CountryBgImage} title={`${cpt.country} (${cpt.abbreviation})`} copy={cpt.intro} flagImage={country.featuredImage?.node} />

        <div className="container single-country-container">
          <div className="baseContainer">
            <div className="grid smallGrid">
              <div className="col-md-12">
                <div className="grid">
                  <div className="col-sm-6 resourceColumn col-grid">
                    <div className="copyWrapper">
                      <h2>ATA Carnet Guarantee Association</h2>
                      <div className="wrap">
                        {cpt.carnetTitle && <p><strong><span style={{ textDecoration: 'underline' }}>{cpt.carnetTitle}</span></strong></p>}
                        {cpt.carnetAddress && <p>{cpt.carnetAddress}</p>}
                        {cpt.carnetPhone && <p>Tel: {cpt.carnetPhone}</p>}
                        {cpt.carnetFax && <p>Fax: {cpt.carnetFax}</p>}
                        {cpt.carnetEmail && <p>Email: <a href={`mailto:${cpt.carnetEmail}`} target="_blank" rel="noopener noreferrer">{cpt.carnetEmail}</a></p>}
                        {cpt.carnetWebsite && <p>Website: <a href={cpt.carnetWebsite} target="_blank" rel="noopener">{cpt.carnetWebsite}</a></p>}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 resourceColumn col-grid">
                    <div className="copyWrapper">
                      <h2>Embassy in the US</h2>
                      <div className="wrap">
                        <p><strong><span style={{ textDecoration: 'underline'  }}>{cpt.embassyTitle}</span></strong></p>
                        {cpt.embassyAddress && <p>{cpt.embassyAddress}</p>}
                        {cpt.embassyPhone && <p>Tel: {cpt.embassyPhone}</p>}
                        {cpt.embassyFax && <p>Fax: {cpt.embassyFax}</p>}
                        {cpt.embassyWebsite && <p>Website: <a href={cpt.embassyWebsite} target="_blank" rel="noopener noreferrer">{cpt.embassyWebsite}</a></p>}
                        {cpt.embassyExtraInfo && (
                          <>
                            <hr />
                            <div dangerouslySetInnerHTML={{ __html: cpt.embassyExtraInfo }} />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 resourceColumn col-grid">
                    <div className="copyWrapper">
                      <h2>ATA Carnet Specifics</h2>
                      {cpt.ataSpecifics && <div className="wrap" dangerouslySetInnerHTML={{ __html: cpt.ataSpecifics }} />}
                    </div>
                  </div>
                  {cpt.tradeShows && (
                    <div className="col-sm-6 resourceColumn col-grid">
                      <div className="copyWrapper">
                        <h2>Trade Shows</h2>
                        <div className="wrap">
                          {cpt.tradeShows.map(({ description, name }) => (
                            <p key={name?.title}><a href={name?.url} target={name?.target || '_self'} rel={name?.target === '_blank' ? 'noopener noreferrer' : ''}>{name?.title}:</a> {description}</p>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default SingleCountryTemplate

export const query = graphql`
  query($id: String) {
    country: wpCountry(id: { eq: $id }) {
      title
      slug
      seo {
        ...seoParts
      }
      cptCountries {
        redirect
        redirectTo {
          ... on WpCountry {
            id
            wpURI
          }
        }
        tradeShows {
          description
          fieldGroupName
          name {
            target
            title
            url
          }
        }
        abbreviation
        ataSpecifics
        carnetAddress
        carnetEmail
        carnetFax
        carnetPhone
        carnetTitle
        carnetWebsite
        country
        embassyAddress
        embassyEmail
        embassyExtraInfo
        embassyFax
        embassyPhone
        embassyTitle
        embassyWebsite
        including
        intro
      }
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`