import React from "react"

import '../css/image-hero.css';

const SingleCountryHero = ({ image, title, copy, flagImage }) => {
  var divStyle = {};

  if( image ) {
    divStyle.backgroundImage = 'url(' + image + ')';
  }

  return (
    <div className="container defaultHero single-country-hero" style={divStyle}>
      <div className="image-sizer"></div>
      <div className="grid smallGrid">
        <div className="col-sm-8">
          <h1>{title}</h1>
          <p>{copy}</p>
          <a href="https://carnet.roanoketrade.com/Carnet/Login.aspx" target="_blank">Apply Today</a>
        </div>
        <div className="col-sm-4">
          {flagImage && (
            <div className="flag-image-wrapper">
              <img src={flagImage.localFile.publicURL} alt={flagImage.altText} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SingleCountryHero